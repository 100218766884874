import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Fade from "react-reveal/Fade"

import Header from "./header"
import Navbar from "./navbar"
import "../style/layout.scss"

import logoIcon from "../images/logo-icon.svg"
import logoBit from "../images/logo-bit.svg"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>
          <Navbar />
          {children}
          <footer className="footer">
            <div className="containerBit">
              <div className="columns">
                <div className="column">
                  <img src={logoIcon} className="footer__icon" />
                </div>
                <div className="column">
                  <p className="footer__title">
                    © {new Date().getFullYear()} Bitcrm
                  </p>
                  <a
                    className="footer__link"
                    target="_blank"
                    href="https://admin.bitcrm.mx/"
                  >
                    Ingresar
                  </a>
                  <Link className="footer__link" to="/terms/">
                    Terminos
                  </Link>
                  <Link className="footer__link" to="/privacy/">
                    Privacidad
                  </Link>
                </div>
                <div className="column">
                  <p className="footer__title">Soporte</p>
                  <Link className="footer__link" to="/soporte/">
                    Soporte
                  </Link>
                  <Link className="footer__link" to="/faqs/">
                    FAQ's
                  </Link>
                  <a
                    className="footer__link"
                    target="_blank"
                    href="https://bitcrm.mx/blog/"
                  >
                    Blog
                  </a>
                </div>
                <div className="column">
                  <p className="footer__title">Social</p>
                  <a
                    className="footer__link"
                    href="https://www.facebook.com/bitcrm"
                    target="_blank"
                  >
                    Facebook
                  </a>
                  <a
                    className="footer__link"
                    href="https://co.linkedin.com/company/holabit"
                    target="_blank"
                  >
                    LinkedIn
                  </a>
                  <a
                    className="footer__link"
                    href="https://twitter.com/holabitmx"
                    target="_blank"
                  >
                    Twitter
                  </a>
                </div>
              </div>
            </div>
          </footer>
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
