import axios from 'axios';

const route = 'https://admin.bitcrm.mx/api/v1/mailer_form'
//const route = 'http://localhost:3000/api/v1/mailer_form'
const mailchimp_rout = 'https://us7.api.mailchimp.com/3.0/lists/ae02738810/members'

export const sendEmail = data => {
    return axios
        .post(`${route}`, data,{
            headers : { 'Access-Control-Allow-Origin' : '*'}
        })
        .then(res => {
            console.log(res);
            return res;
        })
        .catch(err => {
            console.log(err);
        });
};

export const postMailchimp = data => {
    return axios
        .post(`${mailchimp_rout}`, data,{
            headers : { 'Authorization' : 'apikey a6d1ca31b0a604400de78ca80ad128a6-us7',
            'origin' : 'https://us7.api.mailchimp.com',
            'sec-fetch-dest' : 'empty',
            'sec-fetch-mode': 'cors',
            'sec-fetch-site': 'same-origin'}
        })
        .then(res => {
            console.log(res);
            return res;
        })
        .catch(err => {
            console.log(err);
        });
};