import PropTypes from "prop-types"
import React, { Component } from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import Fade from "react-reveal/Fade"
import { PopupText } from "react-calendly"
import selectArrow from "../images/selectArrow.svg"

import logoBit from "../images/logo-bit.svg"
import { sendEmail } from "./userFunctions"

import ReactGA from "react-ga"

function openNav() {
  const navigationWindow = document.getElementById("myNav")
  const overlayContent = document.getElementById("overlayContent")
  navigationWindow.style.opacity = 1
  navigationWindow.style.transitionDelay = "0s"
  navigationWindow.style.visibility = "visible"
  overlayContent.style.opacity = 1
  overlayContent.style.transitionDelay = ".5s"
}

function closeNav() {
  const navigationWindow = document.getElementById("myNav")
  const overlayContent = document.getElementById("overlayContent")
  overlayContent.style.opacity = 0
  overlayContent.style.transitionDelay = "0s"
  navigationWindow.style.opacity = 0
  navigationWindow.style.transitionDelay = ".5s"
  navigationWindow.style.visibility = "hidden"
}

const NavbarBurger = props => (
  <a
    role="button"
    onClick={props.toggleMenu}
    className={`navbar-burger ${props.active ? "is-active" : ""}`}
    aria-label="menu"
    aria-expanded="false"
    data-target="navbarBasic"
  >
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
  </a>
)

export default class Navbar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      prevScrollpos: typeof window !== "undefined" && window.pageYOffset,
      visible: true,
      email: "",
      name: "",
      phone: "",
      company: "",
      nameError: "",
      phoneError: "",
      emailError: "",
      companyError: "",
      day: "",
      dayTime: "",
      dayError: "",
      dayTimeError: "",
    }
  }
  state = {
    activeMenu: true,
    btnClick: false,
    btnClick2: false,
    btnClick3: false,
    btnClick4: false,
    btnClick5: false,
    btnClick6: false,
    btnClick7: false,
    thankYou: false,
  }

  validateFields = () => {
    let nameError = ""
    let phoneError = ""
    let emailError = ""
    let companyError = ""
    let dayError = ""
    let dayTimeError = ""
    var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    if (this.state.name == "") {
      nameError = "Nombre no debe de quedar vacío."
    }

    if (this.state.phone == "") {
      phoneError = "Teléfono no debe de quedar vacío."
    }

    if (this.state.email == "") {
      emailError = "Correo no debe de quedar vacío."
    } else if (
      !email.test(this.state.email) &&
      !this.state.email.includes(".")
    ) {
      emailError = "Correo Invalido"
    }

    if (this.state.company == "") {
      companyError = "Empresa no debe de quedar vacío."
    }

    if (this.state.day == "") {
      dayError = "Día no debe de quedar vacío."
    }

    if (this.state.dayTime == "") {
      dayTimeError = "Horario no debe de quedar vacío."
    }

    if (
      emailError ||
      nameError ||
      companyError ||
      phoneError ||
      dayError ||
      dayTimeError
    ) {
      this.setState({
        emailError,
        nameError,
        phoneError,
        companyError,
        dayError,
        dayTimeError,
      })
      return false
    } else {
      this.setState({
        emailError,
        nameError,
        phoneError,
        companyError,
        dayError,
        dayTimeError,
      })
      return true
    }
  }

  handleSubmit = () => {
    const mailchimp_data = {
      email_address: this.state.email,
      status: "subscribed",
      merge_fields: {
        FNAME: this.state.name,
        PHONE: this.state.phone,
        EMAIL: this.state.email,
        BOOKED: "True",
        HORARIO: this.state.dayTime,
        DIA: this.state.day,
        EMPRESA: this.state.company
      }
    }
    const data = {
      email: this.state.email,
      name: this.state.name,
      phone: this.state.phone,
      country: this.state.company,
      projects: this.state.day,
      projectStatus: this.state.dayTime,
      mailchimp_data: JSON.stringify(mailchimp_data)
    }
    console.log(data)
    const isValid = this.validateFields()
    if (isValid) {
      sendEmail(data).then(res => {
        this.setState({
          thankYou: !this.state.thankYou,
        })
      })
    }
  }

  logger = () => {
    ReactGA.event({
      category: "Form",
      action: "Click",
      label: "Agenda Demo",
      nonInteraction: true,
    })
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    console.log(name, value)
    this.setState({
      [name]: value,
    })
  }

  toggleMenu = () => {
    this.setState({
      activeMenu: !this.state.activeMenu,
    })
  }
  escFunction(event) {
    if (event.keyCode === 27) {
      const navigationWindow = document.getElementById("myNav")
      navigationWindow.style.width = "0%"
    }
  }
  componentDidMount() {
    if (typeof window === "undefined") {
      global.window = {}
    }
    window.addEventListener("scroll", this.handleScroll)

    const script = document.createElement("script")
    const script_sumo = document.createElement("script")

    script.src = "https://embed.small.chat/TFJN7ESJDGFJJG0SG0.js"
    script.async = true

    script_sumo.src = (function (s, u, m, o, j, v) { j = u.createElement(m); v = u.getElementsByTagName(m)[0]; j.async = 1; j.src = o; j.dataset.sumoSiteId = 'e8f59c295c0e1b6e6b563e1104d5c27665cc48604311bfb7f97b01e6ffbf059c'; v.parentNode.insertBefore(j, v) })(window, document, 'script', '//load.sumo.com/')
    script_sumo.async = true

    document.body.appendChild(script)
    document.addEventListener("keydown", this.escFunction, false)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
    document.removeEventListener("keydown", this.escFunction, false)
  }
  handleScroll = () => {
    const { prevScrollpos } = this.state

    const currentScrollPos = window.pageYOffset
    const visible = prevScrollpos > currentScrollPos || "100" > currentScrollPos

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    })

  }
  showCalendly() {
    window["Calendly"].showPopupWidget("https://calendly.com/bitcrm/demo")
  }
  render() {
    return (
      <>
        <div
          className={classnames("navbarHideShow", {
            "navbarHideShow-hidden": !this.state.visible,
          })}
        >
          <Fade top delay={1000}>
            <nav
              className="navbar"
              role="navigation"
              aria-label="main navigation"
            >
              <div className="navbar-brand">
                <a className="navbar-item navbar-itemLogo" href="/">
                  <img src={logoBit} className="navbar-logo" />
                </a>

                <NavbarBurger
                  active={this.state.activeMenu}
                  toggleMenu={this.toggleMenu}
                />
              </div>

              <div
                id="navbarBasic"
                className={`navbar-menu ${this.state.activeMenu ? "is-active" : ""
                  }`}
              >
                <div className="navbar-center">
                  <Link
                    className="navbar-item is-hidden-mobile"
                    to="/#caracteristicas"
                  >
                    Características
                  </Link>

                  <Link
                    className="navbar-item is-hidden-tablet"
                    onClick={this.toggleMenu}
                    to="/#caracteristicas"
                  >
                    Características
                  </Link>

                  <a href="https://bitcrm.mx/blog/" className="navbar-item">
                    Blog
                  </a>

                  <Link className="navbar-item" to="/soporte/">
                    Soporte
                  </Link>
                </div>
                <div className="navbar-end">
                  <div className="navbar-item">
                    <div className="buttons">
                      {/*                       <PopupText
                        pageSettings={{
                          backgroundColor: "ffffff",
                          hideEventTypeDetails: false,
                          hideLandingPageDetails: false,
                          primaryColor: "00a2ff",
                          textColor: "4d5055",
                        }}
                        text="Agenda un demo"
                        url="https://calendly.com/bitcrm/demo"
                      /> */}
                      <span className="button btn-mainMin" onClick={openNav}>
                        Agenda un demo
                      </span>
                      <a
                        className="button btn-clear intro-reveal"
                        target="_blank"
                        href="https://admin.bitcrm.mx/"
                      >
                        Ingresar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </Fade>
        </div>
        <div id="myNav" className="overlay">
          <a className="closebtn" onClick={closeNav}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L15 15" stroke="#9AA1B9" stroke-width="2" />
              <path d="M1 15L15 0.999999" stroke="#9AA1B9" stroke-width="2" />
            </svg>
          </a>

          <div
            className="overlay-content"
            id="overlayContent"
            className={this.state.thankYou ? "hiddenThanks" : "overlay-content"}
          >
            <div id="overlayContentItems">
              <h1>Agenda un demo</h1>
              <p>
                Te enviaremos una invitación a tu correo con el enlace para
                iniciar la video llamada. Confirma la fecha y hora. Cualquier
                cambio o cancelación, mándanos un mensaje para reagendar.
            </p>
              <label>Selecciona un horario</label> <br></br>
              <label>{this.state.dayTimeError}</label>
              <select
                className="select input selectModal"
                style={{ backgroundImage: `url(${selectArrow})` }}
              >
                <option
                  onClick={() =>
                    this.setState({
                      btnClick: !this.state.btnClick,
                      btnClick2: false,
                      dayTime: "Por la mañana",
                    })
                  }
                >
                  Por la mañana
              </option>
                <option
                  onClick={() =>
                    this.setState({
                      btnClick2: !this.state.btnClick2,
                      btnClick: false,
                      dayTime: "Por la tarde",
                    })
                  }
                >
                  Por la tarde
              </option>
              </select>
              <div className="overlay-content__btnRow">
                <button
                  className={
                    this.state.btnClick
                      ? "button btn-clearActive"
                      : "button btn-clear"
                  }
                  onClick={() =>
                    this.setState({
                      btnClick: !this.state.btnClick,
                      btnClick2: false,
                      dayTime: "Por la mañana",
                    })
                  }
                >
                  Por la mañana
              </button>
                <button
                  className={
                    this.state.btnClick2
                      ? "button btn-clearActive"
                      : "button btn-clear"
                  }
                  onClick={() =>
                    this.setState({
                      btnClick2: !this.state.btnClick2,
                      btnClick: false,
                      dayTime: "Por la tarde",
                    })
                  }
                >
                  Por la tarde
              </button>
              </div>
              <label>Selecciona un día</label> <br></br>
              <label>{this.state.dayError}</label>
              <select
                className="select input selectModal"
                style={{ backgroundImage: `url(${selectArrow})` }}
              >
                <option
                  onClick={() =>
                    this.setState({
                      btnClick3: !this.state.btnClick3,
                      btnClick4: false,
                      btnClick5: false,
                      btnClick6: false,
                      btnClick7: false,
                      day: "Lunes",
                    })
                  }
                >
                  Lunes
              </option>
                <option
                  onClick={() =>
                    this.setState({
                      btnClick3: false,
                      btnClick4: !this.state.btnClick4,
                      btnClick5: false,
                      btnClick6: false,
                      btnClick7: false,
                      day: "Martes",
                    })
                  }
                >
                  Mártes
              </option>
                <option
                  onClick={() =>
                    this.setState({
                      btnClick3: false,
                      btnClick4: false,
                      btnClick5: !this.state.btnClick5,
                      btnClick6: false,
                      btnClick7: false,
                      day: "Miércoles",
                    })
                  }
                >
                  Miércoles
              </option>
                <option
                  onClick={() =>
                    this.setState({
                      btnClick3: false,
                      btnClick4: false,
                      btnClick5: false,
                      btnClick6: !this.state.btnClick6,
                      btnClick7: false,
                      day: "Jueves",
                    })
                  }
                >
                  Jueves
              </option>
                <option
                  onClick={() =>
                    this.setState({
                      btnClick3: false,
                      btnClick4: false,
                      btnClick5: false,
                      btnClick6: false,
                      btnClick7: !this.state.btnClick7,
                      day: "Viernes",
                    })
                  }
                >
                  Viernes
              </option>
              </select>
              <div className="overlay-content__btnRow">
                <button
                  className={
                    this.state.btnClick3
                      ? "button btn-clearActive"
                      : "button btn-clear"
                  }
                  onClick={() =>
                    this.setState({
                      btnClick3: !this.state.btnClick3,
                      btnClick4: false,
                      btnClick5: false,
                      btnClick6: false,
                      btnClick7: false,
                      day: "Lunes",
                    })
                  }
                >
                  Lunes
              </button>
                <button
                  className={
                    this.state.btnClick4
                      ? "button btn-clearActive"
                      : "button btn-clear"
                  }
                  onClick={() =>
                    this.setState({
                      btnClick3: false,
                      btnClick4: !this.state.btnClick4,
                      btnClick5: false,
                      btnClick6: false,
                      btnClick7: false,
                      day: "Martes",
                    })
                  }
                >
                  Martes
              </button>
                <button
                  className={
                    this.state.btnClick5
                      ? "button btn-clearActive"
                      : "button btn-clear"
                  }
                  onClick={() =>
                    this.setState({
                      btnClick3: false,
                      btnClick4: false,
                      btnClick5: !this.state.btnClick5,
                      btnClick6: false,
                      btnClick7: false,
                      day: "Miércoles",
                    })
                  }
                >
                  Miércoles
              </button>
                <button
                  className={
                    this.state.btnClick6
                      ? "button btn-clearActive"
                      : "button btn-clear"
                  }
                  onClick={() =>
                    this.setState({
                      btnClick3: false,
                      btnClick4: false,
                      btnClick5: false,
                      btnClick6: !this.state.btnClick6,
                      btnClick7: false,
                      day: "Jueves",
                    })
                  }
                >
                  Jueves
              </button>
                <button
                  className={
                    this.state.btnClick7
                      ? "button btn-clearActive"
                      : "button btn-clear"
                  }
                  onClick={() =>
                    this.setState({
                      btnClick3: false,
                      btnClick4: false,
                      btnClick5: false,
                      btnClick6: false,
                      btnClick7: !this.state.btnClick7,
                      day: "Viernes",
                    })
                  }
                >
                  Viernes
              </button>
              </div>
              <div className="overlay-content__btnRowAlt">
                <div className="overlay-content__inputRow">
                  <label>Nombre completo</label>
                  <br />
                  <input
                    type="text"
                    className="input"
                    placeholder="Nombre"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    autocomplete="on"
                  />
                  <label>{this.state.nameError}</label>
                </div>
                <div className="overlay-content__inputRow">
                  <label>Correo electrónico</label>
                  <br />
                  <input
                    type="email"
                    className="input"
                    placeholder="Correo"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    autocomplete="on"
                  />
                  <label>{this.state.emailError}</label>
                </div>
              </div>
              <div className="overlay-content__btnRowAlt">
                <div className="overlay-content__inputRow">
                  <label>Teléfono</label>
                  <br />
                  <input
                    type="tel"
                    className="input"
                    placeholder="1234-5678"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.handleChange}
                    autocomplete="on"
                  />
                  <label>{this.state.phoneError}</label>
                </div>
                <div className="overlay-content__inputRow">
                  <label>Empresa</label>
                  <br />
                  <input
                    type="text"
                    className="input"
                    placeholder="BitCRM"
                    name="company"
                    value={this.state.company}
                    onChange={this.handleChange}
                    autocomplete="on"
                  />
                  <label>{this.state.companyError}</label>
                </div>
              </div>
              <button
                className="button btn-mainFull"
                onClick={() => {
                  this.handleSubmit()
                  this.logger()
                }}
              >
                Agendar demo
            </button>
            </div>
          </div>
          <div
            className="overlay-contentThanks"
            className={
              this.state.thankYou
                ? "overlay-contentThanks"
                : "overlay-contentThanks hiddenThanks"
            }
          >
            <Fade>
              <p>
                👋 Gracias
                <br />
                Pronto nos comunicaremos contigo.
              </p>
            </Fade>
          </div>
        </div>
      </>
    )
  }
}
